import 'core-js/stable';
import 'regenerator-runtime/runtime';

//localstorageApi
import localStorageSpi from 'local-storage';
import ClipboardJS from 'clipboard';
// sweet alert
import swal from 'sweetalert';

// bootstrap TODO: should update
import 'bootstrap';
// prolly not using..
import 'jquery.easing/jquery.easing';
import 'cd-easypiechart';
import 'bootstrap/js/popover';

// stimulus
import "controllers" // to import stimulus.js which will execute index.js

// application css
import '../packs/stylesheets/application.css.scss';

// Tailwind CSS
import "../stylesheets/application" // ADD THIS LINE

// active storage
require('@rails/activestorage').start(); // add this line for direct upload

// it requires this being set for the form to work
import Rails from '@rails/ujs';
Rails.start();
window.Rails = Rails;

// jquery
window.jQuery = $;
window.$ = $;


// make localstorage and clipboard global
window.$.localStorage = localStorageSpi;
window.Clipboard = ClipboardJS;

import {getCookie,
    popoverListeners,
    clipboard,
    showChromeExtensionAlert,
    initRemotePagination,
    initRowListeners,
    initdestroyableField,
    listingTableListeners
} from "./javascripts/util";

window.getCookie = getCookie;
window.popoverListeners = popoverListeners;
window.showChromeExtensionAlert = showChromeExtensionAlert;
window.clipboard = clipboard;
window.initRemotePagination = initRemotePagination;
window.initRowListeners = initRowListeners;
window.initdestroyableField = initdestroyableField;
window.listingTableLisners = listingTableListeners;

function requireAll(r) {
    r.keys().forEach(r);
};
requireAll(require.context('../packs/javascripts/vendor/', true, /\.(js)$/));
requireAll(require.context('../packs/javascripts/angle/', true, /\.(js)$/));


import {ComparisonSlider} from "./javascripts/vendor/image_optimization";
window.ComparisonSlider = ComparisonSlider;

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    $('.toast').toast({ autohide: false })
    $('#toast').toast('show')
})
