// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//    <div data-controller="hello" data-hello-number-value=4>-->
// <%# <h1 data-target="hello.output"></h1> %>
//      <h5 data-hello-target='output'></h5>-->
//      <button data-action='hello#clicked'>not good!!! xxx</button>-->
//    </div>-->
//


import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    this.outputTarget.textContent = 'Hello, Stimulus!!!XXX YYYYY klsjfalkjfdl'
  }
}
