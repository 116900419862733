function clipboard() {
    $('.clipboard-btn').tooltip({
        trigger: 'click',
        placement: 'bottom'
    });

    function setTooltip(btn, message) {
        $(btn).tooltip('hide')
            .attr('data-original-title', message)
            .tooltip('show');
    }

    function hideTooltip(btn) {
        setTimeout(function () {
            $(btn).tooltip('hide');
        }, 1000);
    }

    var clipboard = new Clipboard('.clipboard-btn');

    clipboard.on('success', function (e) {
        setTooltip(e.trigger, 'Copied!');
        hideTooltip(e.trigger);
    });

    clipboard.on('error', function (e) {
        setTooltip(e.trigger, 'Failed!');
        hideTooltip(e.trigger);
    });
}

function listingTableListeners() {
    $("#listingsTable").on("click", "td.listingClickable", function (e) {
        var checkbox = $(this).find("input[type='checkbox']");
        var checked = checkbox.prop('checked');
        var link = $(this).find(".updateLink").attr('href');
        checkbox.prop('checked', !checked);
        $.ajax({
            url: link,
            type: 'PUT',
            success: function (response) {
                eval(response);
            }
        });
    });
    $("#listingsTable").on("click", "#checkAll", function () {
        var checked = $(this).prop('checked');
        $(this).parents("table").find("input[type='checkbox']").prop('checked', checked);
        $.ajax({
            url: $(this).data('url'),
            data: {filterrific: JSON.parse($(this).val()), checked: checked},
            type: 'PUT',
            success: function (response) {
                eval(response);
            }
        });
    });
    $("#listingsTable").on("click", ".variationButton", function () {
        event.preventDefault();
        var parentAsin = $(this).data("parent-asin");
        $("#parentAsinField").val(parentAsin);
        $("form#filterrific_filter").find("input[type='submit']").click();
    });
    $("#listingsTable").on("click", ".variationSizeButton", function () {
        event.preventDefault();
        var parentAsin = $(this).data("parent-asin");
        var size = $(this).data("size");
        $("#parentAsinField").val(parentAsin);
        $("#sizeField").val(size);
        $("form#filterrific_filter").find("input[type='submit']").click();
    });
    $("#listingsTable").on("click", "#clearParentAsinFilter", function () {
        event.preventDefault();
        $("#parentAsinField").val('');
        $("form#filterrific_filter").find("input[type='submit']").click();
    });
    $("#listingsTable").on("click", "#clearSizeFilter", function () {
        event.preventDefault();
        $("#sizeField").val('');
        $("form#filterrific_filter").find("input[type='submit']").click();
    });
}

function initRowListeners() {
    $("body").on("click", ".clickableRow", function () {
        window.location.href = $(this).data('link');
    });
}

function initRemotePagination() {
    $("body").on("click", ".remotePagination a", function (e) {
        e.preventDefault();
        $.get($(this).attr('href'), function (data) {
            eval(data);
        }, "script")
    })
}

function initdestroyableField() {
    $("form").on("click", ".destroyableField", function (e) {
        e.preventDefault();
        $(this).closest(".jsForm").hide();
        $(this).parent().find(".jsDestroy").val('1');
    });
}

function popoverListeners() {
    $(".pop").popover({trigger: "manual", html: true, animation: false})
        .on("mouseenter", function () {
            var _this = this;
            $(this).popover("show");
            $(".popover").on("mouseleave", function () {
                $(_this).popover('hide');
            });
        }).on("mouseleave", function () {
        var _this = this;
        setTimeout(function () {
            if (!$(".popover:hover").length) {
                $(_this).popover("hide");
            }
        }, 300);
    });
}

function getCookie(name) {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
}

function showChromeExtensionAlert() {
    if (!window.location.host.includes("localhost")) {
        setTimeout(function () {
            $("#chromeExtensionLink").show();
            $("#linkToDownloadExtension").show();
            $(".overlayNoExtension").show();
        }, 3000)
    }
}

$(document).ready(function () {
    clipboard();
    initRowListeners();
    initRemotePagination();
    initdestroyableField();
    listingTableListeners();
    popoverListeners();
    showChromeExtensionAlert();
})


$(document).on('change', '.shimpent_bol_file_upload', function (e) {
    let file = e.target.files[0]

    let fileName = file.name;
    $(`.file_text`).text(fileName);

    $('#newShipmentBol').find('[type="submit"]').removeAttr('disabled')
    $('.upload-errors').html('');

    if (file.size > (10 * 10 ** 6)) {
        $('.upload-errors').append(`<div class="text-danger">The file is too big, maximum allowed file size is 10MB</div>`)
        $('#newShipmentBol').find('[type="submit"]').attr('disabled', true)
    }

    if (!['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
        $('.upload-errors').append(`<div class="text-danger">You can only upload .PDF, JPEG, JPG and PNG files</div>`)
        $('#newShipmentBol').find('[type="submit"]').attr('disabled', true)
    }
})

$(document).on('submit', '#newShipmentBol', function (e) {
    e.preventDefault();

    $.ajax({
        url: $(e.target).attr('action'),
        type: 'POST',
        data: new FormData(e.target),
        cache: false,
        contentType: false,
        processData: false,
        dataType: 'script'
    })
})

$(document).on('change', "#chooseSupplier", function (e) {
    e.preventDefault();
    var selected = $(this).val()
    if (selected !== 'Select Supplier Name') {
        $(".download-buttn").prop('disabled', false)
    } else {
        $(".download-buttn").prop('disabled', 'disabled')
    }
})

$(document).on('click', '.delete-bol', function (e) {
    e.preventDefault();

    $.ajax({
        url: $(e.target).attr('href'),
        type: 'DELETE',
        cache: false,
        contentType: false,
        processData: false,
        dataType: 'script'
    })
})

$('body').on('click', '.modal', function (e) {
    if ($(e.target).hasClass('modal')) {
        $('.modal').modal('hide')
    }
})

$(document).keyup(function (e) {
    if (e.key === "Escape") {
        $('.modal').modal('hide')
    }
});

export {
    getCookie,
    clipboard,
    popoverListeners,
    showChromeExtensionAlert,
    initdestroyableField,
    initRemotePagination,
    initRowListeners,
    listingTableListeners
}