// Bootstrap Tour
// ----------------------------------- 

(function(window, document, $, undefined){

  $(function(){

    // Prepare steps
    var tourSteps = [];
    $('.tour-step').each(function(){
      var stepsOptions = $(this).data();
      stepsOptions.element = '#'+this.id;
      tourSteps.push( stepsOptions );
    });

    if ( tourSteps.length ) {
      // Instance the tour
      var tour = new Tour({
          backdrop: true,
          onShown: function(tour) {
            // BootstrapTour is not compatible with z-index based layout
            // so adding position:static for this case makes the browser
            // to ignore the property
            $('.wrapper > section').css({'position': 'static'});
          },
          onHide: function (tour) {
            // finally restore on destroy and reuse the value declared in stylesheet
            $('.wrapper > section').css({'position': ''});
          },
          steps: tourSteps
        });

      // Initialize the tour
      tour.init();

      
      $('#start-tour').on('click', function(){
        // Start the tour
        tour.restart();
      });
    }

  });

})(window, document, window.jQuery);
